<template>
  <div
    class="available-part"
    :id="id"
  >
    <div
      v-if="isBundle"
      class="available-part__content available-parts__name big-semi-bold"
    >
      {{ availablePart.bundleName }}
    </div>
    <div class="available-parts__cell available-parts__cell--image available-parts__image-container">
      <part-gallery
        :images="availablePart.images"
        :image-versions="availablePart.imageVersions"
        :id="id"
      />
    </div>
    <div
      v-if="!isMobile"
      class="available-parts__cell available-parts__cell--quality"
    >
      <span>
        {{ $t('global.product.quality.class') }}: {{ availablePart.qualityClass }}
      </span>
      <span
        v-if="displayOrigin"
        class="product-info__product-origin"
      >
        {{ $t('ads.product.info.origin') }} {{ checkOrigin }}
      </span>
      <span
        v-if="!isBundle && (availablePart.availablePartDamageModels.length > 0 || availablePart.remarkNotice)"
        class="available-parts__link"
        :class="isOpen ? 'available-parts__link-active' : 'available-parts__link-inactive'"
        @click="buttonClickHandler"
      >
        {{ $t('global.product.damageDescription') }}
      </span>
      <span class="available-part__delivery-information">{{ $t('ITEM_STOCK_AVAILABLE') }} </span>
    </div>
    <div
      v-if="isMobile"
      class="available-parts__cell available-parts__cell--numbers available-parts__origin-parts"
    >
      <template v-if="displayOrigin">
        <div class="origin-part-numbers">
          <strong class="available-parts__origin-parts-headline big-semi-bold">
            {{ $t('ads.product.info.origin') }}
          </strong>
          <span>{{ checkOrigin }}</span>
        </div>
      </template>
    </div>
    <div
      v-if="!isBundle"
      class="available-parts__cell available-parts__cell--numbers"
      :class="{'available-parts__included-parts': isMobile}">
      <template v-if="availablePart.containedPartNumbers">
        <div class="included-part-numbers">
          <strong v-if="isMobile" class="available-parts__included-parts-headline big-semi-bold">
            {{ $t('global.product.included.numbers') }}
          </strong>
          <span>{{ availablePart.containedPartNumbers }}</span>
        </div>
      </template>
    </div>
    <div
      v-if="isBundle || isMobile"
      :class="{'available-parts__cell available-parts__cell--damage-model': isBundle}"
    >
      <available-part-damage-model
        :id="`damageModelBundle-${id}`"
        class="available-parts__damage-model__bundle"
        :class="expandedClass"
        :available-part-damage-models="availablePart.availablePartDamageModels"
        :remark-notice="availablePart.remarkNotice"
      />
      <span
        v-if="showExpandButton && isBundle && !isMobile"
        class="available-parts__link"
        :class="isExpanded ? 'available-parts__link-active' : 'available-parts__link-inactive'"
        @click="buttonExpand"
      >
        {{ isExpanded ? $t('global.show.less') : $t('global.show.more') }}
      </span>
    </div>
    <div
      v-if="!isMobile && !isBundle"
      class="available-parts__cell available-parts__cell--price"
    >
      {{ availablePart.price.displayValue }}
    </div>
    <div
      v-if="!isBundle"
      class="available-parts__cell available-parts__cell--basket"
    >
      <available-part-add-to-basket
        :available-part="availablePart"
        :id="availablePart.id"
      />
    </div>

    <div
      v-if="!isBundle && !isMobile && (availablePart.availablePartDamageModels.length > 0 || availablePart.remarkNotice)"
      class="available-part__content"
      ref="content"
      :style="(() => {
        if (!isOpen) {
          return 'height: 0;';
        }
        return '';
      })()"
    >
      <available-part-damage-model
        :type="'product-detail-page-desktop'"
        :available-part-damage-models="availablePart.availablePartDamageModels"
        :remark-notice="availablePart.remarkNotice"
      />
    </div>
  </div>
</template>

<script>
import productPageStateComputedMixin from "../../utilities/vueMixins/productPageStateComputedMixin/productPageStateComputedMixin";
import globalStateComputedMixin from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";
import AvailablePartAddToBasket from "./availablePartAddToBasket.vue";
import PartGallery from "../partGallery/partGallery.vue";
import accordionMixin from "../../utilities/vueMixins/accordionMixin/accordionMixin";
import AvailablePartDamageModel from "./availablePartDamageModel.vue";

export default {
  components: {
    AvailablePartAddToBasket,
    AvailablePartDamageModel,
    PartGallery,
  },
  mixins: [
    productPageStateComputedMixin,
    globalStateComputedMixin,
    accordionMixin
  ],
  props: {
    id: String,
    availablePart: Object,
    initialOpen: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    isBundle: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isOpen: this.initialOpen,
      isExpanded: false,
      showExpandButton: false,
      expandedClass: ''
    };
  },
  mounted() {
    this.getHeightOfDamageModel()
  },
  computed: {
    displayOrigin() {
      return this.availablePart.originInfoEnabled;
    },
    checkOrigin() {
      if (this.availablePart.origin === 'IMPORTER') {
        return this.$t('ads.product.info.origin.importer');
      } else {
        return this.$t('ads.product.info.origin.oem');
      }
    }
  },
  methods: {
    buttonExpand() {
      this.isExpanded = !this.isExpanded;
      this.expandedClass = this.isExpanded
        ? 'available-parts__damage-model__bundle--active'
        : 'available-parts__damage-model__bundle--inactive';
    },
    getHeightOfDamageModel() {
      let height = document.getElementById(`damageModelBundle-${this.id}`)?.clientHeight;
      if (height > 150) {
        this.expandedClass = 'available-parts__damage-model__bundle--inactive';
        this.showExpandButton = true;
      }
    }
  }
};
</script>

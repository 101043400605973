import SingleOrder from '../../../services/accountOrders/accountOrders'

const accountOrderMixin = {
  methods: {
    getInvoicePdf(orderNumber) {
      this.isInvoiceLoading = {...this.isInvoiceLoading, [orderNumber]: true}
      document.getElementById(`account.invoice.button${orderNumber}`).style.display = 'none'
      SingleOrder()
          .getInvoicePdf(orderNumber)
          .then((blob) => {
            this.isInvoiceLoading = {...this.isInvoiceLoading, [orderNumber]: false}
            if (blob == null) {
              document.getElementById(`account.invoice.button${orderNumber}`).style.display = 'block'
              return
            }
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = `${orderNumber}_invoice.pdf`
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            a.remove()
          })
          .catch(() => {
            this.isInvoiceLoading[orderNumber] = false;
            this.disableButton[orderNumber] = false;
          });
    },
  }
}

export default accountOrderMixin

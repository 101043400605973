<template>
  <div
    class="cart-select"
  >
    <div
      class="cart-select__wrapper"
    >
      <select-vue
        class="cart-select__select"
        :initial-value="selectedOption.value"
        :options="selectableCarts"
        :use-native-on-mobile="false"
        :use-custom-select="true"
        :key="selectedOption.value"
        ref="cartSelect"
        @change="activateCart"
      >
        <template v-slot="{option}">
          <span
            class="cart-select__option--label"
          >
            {{ option.label }}
          </span>
          <span
            class="cart-select__option--edit"
            @click.stop="editCart(option.value)"
          />
          <span
            v-if="!option.active"
            class="cart-select__option--delete"
            @click.stop="confirmDeleteCart(option.value)"
          />
          <span
            v-else
            class="cart-select__option--empty"
          />
        </template>
        <template
          #additionalContent
        >
          <button
            v-if="useAddNew"
            type="button"
            class="cart-select__new-cart button button--light"
            @click="openAddCartModal"
          >
            <span
              class="cart-select__new-cart--label"
            >
              {{ $t('global.cart.new.label') }}
            </span>
          </button>
        </template>
      </select-vue>
    </div>
    <modal
      class="cart-select__modal"
      id="add-cart-modal"
      @modalClosed="reset"
    >
      <template v-if="cartEdit">
        <span
          class="cart-select__modal--headline | headline headline--type_h2"
          v-html="$t('global.cart.new.modal.headline.update')"
        />
        <Form
          id="addCartForm"
          ref="addCartForm"
          :validation-schema="validationSchema"
          class="cart-select__modal--form | form"
          method="post"
          @submit="submitCreateCartForm"
          @invalid-submit="onInvalidSubmit"
          @keydown.enter.prevent
        >
          <div class="form__group">
            <input-vee-v
              name="cartName"
              :intiial-value="cartNameEdit"
              :label="$t('global.cart.new.modal.input.name.label')"
            />
          </div>
          <div class="form__group">
            <button
              type="submit"
              name="button-create-cart-submit"
              class="button button--alt"
            >
              {{ $t('global.cart.new.modal.button.save.label') }}
            </button>
          </div>
        </Form>
      </template>
      <template v-else>
        <span
          class="cart-select__modal--headline | headline headline--type_h2"
        >
          {{ cartToDelete }}
        </span>
        <span
          class="cart-select__modal--headline | headline headline--type_h3"
          v-html="$t('global.cart.new.modal.headline.delete')"
        />
        <div class="cart-select__button-group">
          <button
            class="button button--alt"
            @click="closeAddCartModal"
          >
            {{ $t('global.cart.new.modal.button.back') }}
          </button>
          <button
            class="button button--filled-default"
            @click="deleteCart(cartIdDelete)"
          >
            {{ $t('global.cart.new.modal.button.delete.cart') }}
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import globalStateComputedMixin from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin.js";
import Modal from '../modal/modal.vue';
import SelectVue from "../select/select.vue";
import {Form} from 'vee-validate';
import * as yup from 'yup';
import InputVeeV from "../input/inputVeeV.vue";

export default {
  mixins: [
    globalStateComputedMixin
  ],
  components: {
    SelectVue,
    InputVeeV,
    Modal,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form
  },
  props: {
    useAddNew: {
      type: Boolean,
      default: true
    }
  },
  created() {
    const vm = this;
    this.$store.subscribe(mutation => {
      if (mutation.type === 'updateCarts') {
        vm.$nextTick(() => {
          mutation.payload?.filter(cart => cart.active)
            .forEach(cart => {if (vm && vm.$refs && vm.$refs.cartSelect) {
              vm.$refs.cartSelect.setValue(cart.externalId);
            };
            })
        });
      }
    });
  },
  mounted() {
    this.cartId = this.cart.externalId;
  },
  computed: {
    cartName() {
      return this.$refs.addCartForm?.values.cartName === undefined ? null : this.$refs.addCartForm.values.cartName;
    },
    selectableCarts() {
      let carts = this.carts.map(cart => {
        return Object.assign(cart, {
          label: cart.name ? `${cart.name} (${cart.size})` : this.$t('global.cart') + ` (${cart.size})`,
          value: cart.externalId
        });
      });
      return carts || [];
    },
    selectedOption() {
      return this.selectableCarts[this.activeOptionIndex];
    },
    activeOptionIndex() {
      return this.selectableCarts.findIndex(x => x.value === this.cart.externalId || x === this.cart.externalId);
    },
    cartToDelete() {
      const cart = this.selectableCarts.find(x => x.value === this.cartIdDelete);
      return cart ? cart.label : '';
    },
  },
  data() {
    return {
      validationSchema: yup.object({
        cartName: yup.string()
          .required(this.$t('global.validation.required'))
          .test('uniqueCartName',
            this.$t('global.validation.uniqueName'),
            function (value) {
              return (this.cartName === value && this.cartIdEdit !== null)
                || window.__initialAppState.global.carts.every(cart => cart.name !== value)
            }
          )
          .min(1, this.$t('global.validation.min', [1]))
          .max(60, this.$t('global.validation.max', [20]))
          .matches(/^[a-zA-Z0-9_-]*$/, this.$t('global.validation.alphaDash')),
      }),
      cartId: null,
      cartIdEdit: null,
      cartIdDelete: null,
      cartEdit: false,
      cartUsers: [],
      results: null,
      cartNameEdit: '',
    };
  },
  methods: {
    onInvalidSubmit({ values, errors, results }) {
      this.results.results = results;
      this.results.values = values;
      this.results.errors = errors;
    },
    openAddCartModal() {
      this.$store.dispatch('showModal', {id: 'add-cart-modal', action: 'open'});
    },
    closeAddCartModal() {
      this.$store.dispatch('hideModal', {id: 'add-cart-modal'});
    },
    submitCreateCartForm() {
      const payload = {
        externalId: this.cartIdEdit,
        name: this.cartName,
        users: this.cartUsers.map(cartUser => cartUser.userId)
      };
      this.$store.dispatch('updateCart', payload).
      then(() => {
        this.reset();
        this.cartId = this.cart.externalId;
      });
    },
    activateCart(cartId) {
      if (cartId) {
        this.$store.dispatch('activateCart', {externalId: cartId.value});
      }
    },
    confirmDeleteCart(cartId) {
      this.cartIdDelete = cartId;
      this.cartEdit = false;
      this.openAddCartModal()
    },
    deleteCart(cartId) {
      if (cartId) {
        this.$store.dispatch('deleteCart', {externalId: cartId}).then(() => {
          this.cartId = this.cart.externalId;
          this.closeAddCartModal()
        });
      }
    },
    editCart(cartId) {
      const editCart = this.carts.find(cart => cart.externalId === cartId);
      this.cartIdEdit = cartId;
      this.cartUsers = editCart.sharedWithUsers.map(user => ({ userId: user })) || [];
      this.cartEdit = true;
      this.cartNameEdit = editCart.name;
      this.openAddCartModal();
    },
    addUser() {
      this.cartUsers.push({ userId: '' });
    },
    deleteUser(index) {
      this.cartUsers.splice(index, 1);
    },
    reset() {
      // this.$refs.addCartForm.setFieldValue('cartName', null);
      this.cartIdEdit = null;
      this.cartUsers = [];
    }
  }
};
</script>

<template>
  <div
    class="account-orders-list-details"
  >
    <div class="account-orders-list-details__order-number">
      {{ $t('account.order.subheadline', [orderLine.orderNumber]) }}
      <template
        v-if="orderLine.invoiceId"
      >
        ({{ orderLine.invoiceId }})
      </template>
    </div>
    <div
      class="account-orders-list__order-price default-bold"
    >
      {{ orderLine.grandTotal.displayValue }}
    </div>
    <div
      class="account-orders-list-details__invoice-wrapper"
    >
      <button
        class="button button--outlined-default | account-orders-list-details__order-button account-orders-list-details__order-button-download"
        :disabled="!orderLine.invoiceId || isInvoiceLoading[orderLine.orderNumber] || disableButton[orderLine.orderNumber]"
        @click="getInvoicePdf(orderLine.orderNumber)"
        :style="{ cursor: isInvoiceLoading[orderLine.orderNumber] ? 'wait' : 'pointer' }"
      >
        {{ isInvoiceLoading[orderLine.orderNumber] ? $t('account.invoice.loading') : $t('account.invoice.button') }}
      </button>
      <div
        :id="`account.invoice.button${orderLine.orderNumber}`"
        style="display: none;text-align: end"
      >
        {{ $t('account.invoice.download.error') }}
      </div>
    </div>
  </div>
</template>

<script>
import accountOrderMixin from '../../utilities/vueMixins/account/accountOrderMixin'
import accountPageStateComputedMixin from "../../utilities/vueMixins/account/accountPageStateComputedMixin";

export default {
  mixins: [
    accountOrderMixin,
    accountPageStateComputedMixin
  ],
  props: {
    orderLine: Object
  },
  data() {
    return {
      isInvoiceLoading: {},
      disableButton: {}
    }
  },
}
</script>
